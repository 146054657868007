
  .gradient-cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 32px;
    padding: 30px;
    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr;
    }
  }
  
  .container-title {
    text-align: center;
    padding: 0 !important;
    margin-bottom: 40px;
    font-size: 40px;
    color: #fff;
    font-weight: 600;
    line-height: 60px;
  }
  
  .card {
    border: 0;
    margin: 48px;
  }
  
  .container-card {
    position: relative;
    border: 2px solid transparent;
    background: linear-gradient(71deg, #080509, #1a171c, #080509);
    background-clip: padding-box;
    border-radius: 45px;
    padding: 40px;
    z-index: 2;
    img {
      margin-bottom: 32px;
    }
  }
  .container-card-achievement{
    position: relative;
    border: 2px solid transparent;
    background: linear-gradient(71deg, #080509, #1a171c, #080509);
    background-clip: padding-box;
    border-radius: 45px;
    padding: 40px;
    z-index: 2;
    img {
      margin-bottom: 32px;
    }
  }
  .bg-blue-box {
    position: relative;
  }
  
  .bg-blue-box::after {
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    right: -1px;
    content: "";
    z-index: 0;
    border-radius: 45px;
    background: linear-gradient(71deg, #0c0a0e, #5f6fad, #0c0a0e);
  }

  .card-title {
    font-weight: 600;
    color: white;
    letter-spacing: -0.02em;
    line-height: 40px;
    font-style: normal;
    font-size: 28px;
    padding-bottom: 8px;
  }
  
  .card-description {
    font-weight: 600;
    line-height: 32px;
    color: hsla(0, 0%, 100%, 0.5);
    font-size: 16px;
    max-width: 470px;
  }
  