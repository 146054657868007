/************ Projects Css ************/
.project {
  padding-top: 5vh;
}
.project h2 {
  font-size: 45px;
  font-weight: 700;
  text-align: center;
}
.project p {
  color: #b8b8b8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
}
.project .nav.nav-pills {
  width: 72%;
  margin: 0 auto;
  border-radius: 50px;
  background-color: rgb(255 255 255 / 10%);
  overflow: hidden;
}
.project .nav.nav-pills .nav-item {
  width: 33.33333%;
}
.project .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 17px 0;
  color: #fff;
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}
.project .nav.nav-pills .nav-link::before {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  background: linear-gradient(
    90.21deg,
    #1d2350 -5.91%,
    #0a7a48 30%,
    #4d4f8d 111.58%
  );
  top: 0;
  left: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.project .nav.nav-pills .nav-link.active::before {
  width: 100% !important;
}
.project .nav.nav-pills .nav-link.active {
  border: 1px solid rgba(255, 255, 255, 1);
}
.nav-link#projects-tabs-tab-first {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 55px 0px 0px 55px;
}
.nav-link#projects-tabs-tab-second {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.nav-link#projects-tabs-tab-third {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0 55px 55px 0;
}

.project {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
}
.description {
  display: flex;
  justify-content: center;
  align-items: center;
}
.img {
  display: flex;
  justify-content: center;
  align-items: center;
}
.next-gradient {
  background: linear-gradient(135deg, #0e0d0d, #202020);
}
.SkillList {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 12px;
  margin-bottom: 20px;
}

.SkillItem {
  font-size: 16px;
  font-weight: 400;
  color: gray;
  border: 1px solid gray ;
  border-radius: 12px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  @media (max-width: 768px) {
    font-size: 14px;
    padding: 8px 12px;
  }
  @media (max-width: 500px) {
    font-size: 14px;
    padding: 6px 12px;
  }
}
.bg-gradient-to-b-from-dark-blue-to-black {
  background-image: linear-gradient(to bottom, #040f25, #000000);
}

.SkillImage {
  width: 24px;
  height: 24px;
}
.stars, .twinkling{
	position:absolute;
	display:block;
	top:0; bottom:0;
	left:0; right:0;
	width:100%; height:100%;
}

.stars {
	z-index: 0;
	background: transparent url('https://i.ibb.co/g91MwKZ/stars.png') repeat top center;
}
