/************ Banner Css ************/
.banner {
  margin-top: 0;
  padding: 260px 0 100px 0;
  background-image: url("../../assets/imgs/adelin-preda--tOr_T4qTpQ-unsplash.jpg");
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  height: calc(100vh );
}
.banner .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: linear-gradient(
    90.21deg,
    #1d2350 -5.91%,
    #0a7a48 30%,
    #4d4f8d 111.58%
  );
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
}
/* .banner h1 {
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  display: block;
} */
.banner p {
  color: #b8b8b8;
  /* font-size: 18px; */
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 75%;
  /* text-align: center; */
}
.img-container {
  display: block;
  /* other properties */
}

@media only screen and (max-width: 768px) {
  .img-container {
    display: none;
  }
}
/* .banner button {
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
  position: relative;
  background-color: transparent;
  transition: 0.3s ease-in-out;
}
.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
.banner button:hover {
  background-color: #fff;
  transition: 0.3s ease-in-out;
  color: black;
}
.banner button:hover svg {
  margin-left: 25px;
} */

.glow-btn {
  color: white;
  padding: 10px 40px;
  border: 1px solid white;
  border-radius: 8px;
  cursor: pointer;
  /* letter-spacing: 2px; */
  /* font-size: 25px; */
  
  &:hover {
    transition: all 1s;
    background: #0C2C57;
    box-shadow: 0 0 5px #f7f7a5, 0 0 25px #f7f7a5, 0 0 50px #f7f7a5, 0 0 100px #f7f7a5;
    border:0;
  }
  
}
.banner img {
  /* animation: updown 3s linear infinite; */
}
@keyframes updown {
  0% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(-20px);
  }
}
.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}
.stars, .twinkling{
	position:absolute;
	display:block;
	top:0; bottom:0;
	left:0; right:0;
	width:100%; height:100%;
}

.stars {
	z-index: 0;
	background: transparent url('https://i.ibb.co/g91MwKZ/stars.png') repeat top center;
}

/* .twinkling{
	z-index: 1;
	background:transparent url('https://i.ibb.co/pnJQm6V/twinkling.png') repeat top center;
	animation: move-twink-back 200s linear infinite;
}
@keyframes move-twink-back {
	from {background-position:0 0;}
	to {background-position:-10000px 5000px;}
} */
.ring {
  position: relative;
  width: 500px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: transparent;
}
.ring {
  --tw-ring-color: transparent !important; /* To remove the ring */
}
.ring i {
  position: absolute;
  inset: 0;
  border: 2px solid #fff;
  transition: 0.5s;
}
.ring i:nth-child(1) {
  border-radius: 38% 62% 63% 37% / 41% 44% 56% 59%;
  animation: animate 6s linear infinite;
}
.ring i:nth-child(2) {
  border-radius: 41% 44% 56% 59%/38% 62% 63% 37%;
  animation: animate 4s linear infinite;
}
.ring i:nth-child(3) {
  border-radius: 41% 44% 56% 59%/38% 62% 63% 37%;
  animation: animate 10s linear infinite;
}
.ring:hover i {
  border: 6px solid var(--clr);
  filter: drop-shadow(0 0 20px var(--clr));
}
@keyframes animate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes animate2 {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.btn-shine {
  position: relative;
  padding: 12px 0;
  color: #ffffff;
  background: linear-gradient(to right, #4d4d4d 0, white 50%, #4d4d4d 100%);
  background-size: 200% auto;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 3s linear infinite;
  text-decoration: none;
  white-space: nowrap;
}

@keyframes shine {
  0% {
    background-position: 200% center;
  }
  100% {
    background-position: -200% center;
  }
}