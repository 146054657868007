.footer {
  padding: 0 0 50px 0;
  /* background-image: url("../../assets/imgs/footer-bg.png"); */
  background-color: black;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.footer .container {
  justify-content: center !important;
}

.footer .social-icon {
  width: fit-content;
}
.footer img {
  width: 26%;
}
.footer p {
  font-weight: 400;
  font-size: 14px;
  color: #b8b8b8;
  letter-spacing: 0.5px;
  margin-top: 20px;
  white-space: nowrap;
}
