/* Contact section styles */
@media only screen and (max-width: 768px) {
  .contact-image {
    display: none;
  }
}
.form-container {
  padding-top: 112px;
}
.contact {
  padding: 4rem 0;
  height: 80vh;
}

.contact h2 {
  font-size: 2.5rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
}

.contact form {
  margin-top: 1.5rem;
}

.contact input,
.contact textarea {
  background-color: #495057;
  border: none;
  border-radius: 0.25rem;
  color: #ffffff;
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
}

.contact input::placeholder,
.contact textarea::placeholder {
  color: #adb5bd;
}

.contact textarea {
  resize: none;
}

.contact button {
  background-color: #198754;
  border: none;
  border-radius: 0.25rem;
  color: #ffffff;
  font-weight: 600;
  padding: 0.5rem 1.5rem;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact button:hover {
  background-color: #157347;
}
.contact button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
